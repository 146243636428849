import React from "react";
import { FaHourglassStart } from "react-icons/fa";

interface WaitingScreenProps {
  message?: string; // Optional custom message
}

const WaitingScreen: React.FC<WaitingScreenProps> = ({
  message = "Quiz has started! Waiting for the first question...",
}) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col justify-center items-center gap-1 text-center animate-fadeIn p-8 rounded-lg bg-primary-500 shadow-md">
        <FaHourglassStart className="text-primary-50 text-6xl mb-4 animate-spin-slow" />
        <h1 className="text-primary-50 text-3xl font-bold mb-4">Please Hold On!</h1>
        <p className="text-primary-100">{message}</p>
      </div>
    </div>
  );
};

export default WaitingScreen;