import { FC } from "react";

const Header: FC = () => {
  return (
    <header className="w-full h-[60px] p-2 md:p-3 flex items-center justify-between shadow-md shadow-primary-500">
      <img src="/assets/logo.webp" alt="Logo" className="w-24 " />
    </header>
  );
}

export default Header;