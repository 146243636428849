const AboutToStart = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-primary-50 text-5xl font-bold mb-6 animate-fadeInDown">
        Quiz
      </h1>
      <p className="text-primary-100 text-xl mb-12 text-center animate-fadeIn">
        Are you ready to start the quiz? <br /> Take a deep breath and let's begin!
      </p>
    </div>
  )
}

export default AboutToStart;