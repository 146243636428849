import { toast } from "react-toastify";

export const errorAlert = (message: string) => {
  toast.dismiss();
  toast.error(message);
}

export const successAlert = (message: string) => {
  toast.dismiss();
  toast.success(message);
}

export const infoAlert = (message: string) => {
  toast.dismiss();
  toast.info(message);
}
