import { FC, ReactNode, useReducer } from "react";
import UserContext, { initialContextValue } from "../Context/userContext";
import { IUser, TUserActions } from "../types/user.type";

const UserProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {

  const userReducer = (state: IUser, action: TUserActions): IUser => {
    switch (action.type) {
      case "SET_USER":
        return {
          ...state,
        };
      default:
        return state;
    }
  }

  const [userContext, setUserContext] = useReducer(userReducer, initialContextValue);

  return <UserContext.Provider
    value={{ userContext, setUserContext }}
  >{children}</UserContext.Provider>;
}

export default UserProvider;