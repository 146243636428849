import { Route, Routes } from 'react-router';
import PrivateLayout from './Layout/PrivateLayout';
import Quizz from './Pages/Quizz';
import Authentication from './Pages/Authetication';
import NoQuizz from './Components/pages/auth/NoQuizz';


function App() {
  return (
    <Routes>
      <Route path="/" element={<PrivateLayout />}>
        <Route index path="/quizz" element={<Quizz />} />
        <Route index path="/auth" element={<Authentication />} />
        <Route index path="/no-quizz" element={<NoQuizz />} />
      </Route>
    </Routes>
  );
}

export default App;
