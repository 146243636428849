import { useEffect, useState } from "react";
import { getUserData } from "../../../lib/user";
import QuestionsContaienr from "./QuestionsContainer";
import QuestionsContainer from "./QuestionsContainer";
import ResultComponent from "./ResultComponent";
import AboutToStart from "./AboutToStart";
import { miles_quizz_db } from "../../../services/SupabaseClientInstance";
import WaitingScreen from "./WaitingScreen";

const QuizzContainer = () => {
  const [userData, setUserData] = useState<any>({});
  const [quizzListData, setQuizzListData] = useState<any>([]);

  const isCurrentUser = 2;
  const LeaderBoard = [{
    name: "Sachin Tendulkar",
    score: 10
  },
  {
    name: "Saurav Ganguly",
    score: 9,
  },
  {
    name: "Virat Kohli",
    score: 8,
  },
  {
    name: "MS Dhoni",
    score: 7,
  },
  {
    name: "Rohit Sharma",
    score: 6,
  },
  {
    name: "Ravindra Jadeja",
    score: 5,
  },
  {
    name: "Jasprit Bumrah",
    score: 4,
  },
  {
    name: "Mohammed Shami",
    score: 3,
  },
  {
    name: "Siraj",
    score: 2,
  },
  {
    name: "Rahual Chahar",
    score: 1,
  }
  ]
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getQuizzData = async () => {
    try {
      const { data, error } = await miles_quizz_db.from('quizz_list').select('*').eq('id', userData?.quizz_id);
      if (!error) {
        setQuizzListData(data[0]);
      }
    } catch (error) {
      console.error("Error while fetching quizz data", error);
    } finally {

    }
  };

  useEffect(() => {
    setUserData(getUserData());
  }, []);

  useEffect(() => {
    if (userData?.quizz_id) {
      getQuizzData();
    }
  }, [userData?.quizz_id]);

  useEffect(() => {
    const subscription = miles_quizz_db
      .channel(`quizz_list:id=eq.${userData?.quizz_id}`)
      .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'quizz_list' }, (payload) => {
        setQuizzListData(payload?.new);
      })
      .subscribe((status, error) => {
        console.log('Subscription status:', status);
        if (error) {
          console.error('Subscription error:', error);
        }
      });

    return () => {
      miles_quizz_db.removeChannel(subscription);
    };
  }, [])


  return (
    <div className="flex flex-col w-full gap-2">
      {/* <button
        onClick={toggleDrawer}
        className="px-4 py-2 bg-primary-500 text-white rounded hover:bg-primary-600"
      >
        LeaderBoard
      </button> */}

      {/* Drawer */}
      {/* <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} position="right" bgColor="bg-primary-600" width="w-[90%]" >
        <div className="flex flex-col justify-start items-center h-full">
          <div className="grid grid-cols-12 gap-4 mt-1 text-primary-100 rounded-lg shadow-lg p-4">
            <div className="col-span-2 flex items-center justify-center">
              Position
            </div>
            <div className={`col-span-8 flex items-center justify-center font-semibold text-primary-100`}>
              Name
            </div>
            <div className="col-span-2 flex items-center justify-center font-bold text-primary-100">
              Score
            </div>
          </div>
          <div className="overflow-auto w-full h-[calc(100vh-100px)]">
            {LeaderBoard?.map((leader, index) => (
              <div className="grid grid-cols-12 gap-4 mt-2 bg-gray-800 text-white rounded-lg shadow-lg p-4 overflow-auto">

                <div className="col-span-2 flex items-center justify-center">
                  <div
                    className={`w-12 h-12 inline-flex items-center justify-center rounded-full text-lg font-bold ${index + 1 === 1
                      ? "bg-[#FFD700] text-black"
                      : index + 1 === 2
                        ? "bg-[#C0C0C0] text-black"
                        : index + 1 === 3
                          ? "bg-[#CE8946] text-black"
                          : "bg-primary-100 text-primary-300"
                      }`}
                  >
                    {index + 1}
                  </div>
                </div>


                <div
                  className={`col-span-8 flex items-center font-semibold ${isCurrentUser === index + 1
                    ? "text-green-300"
                    : "text-primary-300 hover:text-white"
                    }`}
                >
                  {leader.name}
                </div>


                <div className="col-span-2 flex items-center justify-center font-bold text-primary-100">
                  <span>{leader.score}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Drawer> */}{ }
      {quizzListData?.quizz_start_status === 0 ? <AboutToStart /> : quizzListData?.quizz_start_status === 2 ?
        <ResultComponent quizzData={quizzListData} /> :
        <>
          {
            quizzListData?.isStarted ? <QuestionsContainer quizz={quizzListData} userData={userData} /> : <WaitingScreen message="Get ready! First question will be here shortly." />
          }
        </>
      }
    </div>
  )
}

export default QuizzContainer;