import { createContext } from "react";
import { IUser, IUserContext } from "../types/user.type";

export const initialContextValue: IUser = {
  userData: {
    name: "",
    phone: "",
    email: "",
    authCode: "",
    sessionCode: ""
  }
};

const UserContext = createContext<IUserContext>({
  userContext: initialContextValue,
  setUserContext: () => { }
});

export default UserContext;