import { FC } from "react";

const Question: FC<
  {
    question: string;
  }
> = ({
  question
}) => {
    return (
      <div className="p-2  bg-gradient-to-tl from-primary-700 to-primary-500 rounded-md text-sm">
        {question}
      </div>
    );
  }

export default Question;