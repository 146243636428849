import { FC, useEffect, useState } from "react";
import Option from "./Option";
import Question from "./Question";
import { miles_quizz_db } from "../../../services/SupabaseClientInstance";
import { errorAlert, infoAlert } from "../../../lib/alerts";
import LoadingComponent from "../../common/LoadingNotFound";
import DataNotFound from "../../common/DataNotFound";
export const Alphabates = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"];
const QuestionsContainer: FC<{
  userData: any;
  quizz: any
}> = ({
  userData,
  quizz
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [liveDataRowId, setLiveDataRowId] = useState<number | null>(null);
    const [revelData, setRevelData] = useState<{
      correct_option: number | null,
      isReveled: boolean
    }>({
      correct_option: null,
      isReveled: false
    });
    const [questionData, setQuestionData] = useState<{
      question: string;
      options: string[];
    } | null>(null);

    const getCorrectQuestionData = async () => {
      setLoading(true)
      try {
        let { data: quizz_questions_data, error } = await miles_quizz_db
          .from('quizz_questions_data')
          .select('question,answers')
          .eq("question_status", true)
          .eq("id", quizz?.quizz_current_question);
        if (!error) {
          if (quizz_questions_data && quizz_questions_data.length > 0) {
            setQuestionData({
              question: quizz_questions_data[0].question,
              options: quizz_questions_data[0].answers
            });
          } else {
            setQuestionData(null);
          }
        } else {
          errorAlert("Error fetching data");
        }
      } catch (error) {
        console.log("Error fetching data", error)
      } finally {
        setLoading(false)
      }
    }

    const saveUserAnswer = async (option: string) => {
      try {
        let { data, error } = await miles_quizz_db
          .from('user_answers_data')
          .insert([
            {
              quizz_id: userData?.quizz_id,
              question_id: quizz?.quizz_current_question,
              user_id: userData?.user_id,
              live_question_row_id: liveDataRowId,
              correct_ans: Alphabates.indexOf(option),
              answerTime: "now()"
            }
          ]);
        if (!error) {
          console.log("User answer saved", data)
        } else {
          errorAlert("Error saving user answer");
        }
      } catch (error) {
        console.log("Error saving user answer", error
        )
      }
    }

    const checkAlreadyAnswered = async (tag: string) => {
      try {
        let { data, error } = await miles_quizz_db
          .from('user_answers_data')
          .select('id')
          .eq("quizz_id", userData?.quizz_id)
          .eq("question_id", quizz?.quizz_current_question)
          .eq("live_question_row_id", liveDataRowId)
          .eq("user_id", userData?.user_id);
        if (!error) {
          if (data && data.length === 0) {
            saveUserAnswer(tag);
          }
        } else {
          errorAlert("Error fetching data");
        }
      } catch (error) {
        console.log("Error fetching data", error)
      }
    }

    const getSelectedOption = (tag: string) => {
      if (revelData.isReveled) {
        infoAlert("Answer is already reveled");
        return
      }
      if (selectedOption === "") {
        setSelectedOption(tag);
        checkAlreadyAnswered(tag);
      } else {
        infoAlert("You have already selected an option");
      }
    }

    const getLiveDataRowId = async () => {
      try {
        let { data, error } = await miles_quizz_db
          .from('quizz_questions_live_data')
          .select('id')
          .eq("quizz_id", userData?.quizz_id)
          .eq("question_id", quizz?.quizz_current_question);
        if (!error) {
          if (data && data.length > 0) {
            setLiveDataRowId(data[0].id);
          }
        } else {
          errorAlert("Error fetching data");
        }
      } catch (error) {
        console.log("Error fetching data", error)
      }
    }

    const checkQuestionAlreadyAnswered = async () => {
      try {
        let { data, error } = await miles_quizz_db
          .from('user_answers_data')
          .select('correct_ans')
          .eq("quizz_id", userData?.quizz_id)
          .eq("question_id", quizz?.quizz_current_question)
          .eq("user_id", userData?.user_id);
        if (!error) {
          if (data && data.length > 0) {
            console.log("Question already answered")
            setSelectedOption(Alphabates[data[0].correct_ans]);
          }
        } else {
          errorAlert("Error fetching data");
        }
      } catch (error) {
        console.log("Error fetching data", error)
      }
    }

    useEffect(() => {
      setSelectedOption("");
      setRevelData({
        correct_option: null,
        isReveled: false
      });


      if (quizz?.quizz_current_question) {
        getCorrectQuestionData();
        getLiveDataRowId();
        checkQuestionAlreadyAnswered();
      }
    }, [quizz]);

    useEffect(() => {
      const subscription = miles_quizz_db
        .channel(`quizz_questions_live_data:quizz_id=eq.${userData?.quizz_id}`)
        .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'quizz_questions_live_data' }, (payload) => {
          setRevelData({
            correct_option: payload?.new?.correct_ans,
            isReveled: payload?.new?.isRevealed
          })
        })
        .subscribe((status, error) => {
          if (error) {
            console.error('Subscription error:', error);
          }
        });

      return () => {
        miles_quizz_db.removeChannel(subscription);
      };
    }, []);

    useEffect(() => {
      const subscription = miles_quizz_db
        .channel(`quizz_questions_live_data_ins:quizz_id=eq.${userData?.quizz_id}`)
        .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'quizz_questions_live_data' }, (payload) => {
          setLiveDataRowId(payload?.new?.id)
        })
        .subscribe((status, error) => {
          if (error) {
            console.error('Subscription error:', error);
          }
        });

      return () => {
        miles_quizz_db.removeChannel(subscription);
      };
    }, []);





    return (
      <>
        {loading ? <LoadingComponent message="Loading next question..." /> :
          <>
            {questionData ?
              <>
                <Question question={questionData?.question} />
                <div className="grid sm:grid-cols-2 gap-2">
                  {
                    questionData.options.map((option, index) => (
                      <Option
                        revelData={revelData}
                        key={"option-" + index} option={option} tag={Alphabates[index]} selected={selectedOption} setSelectedOption={
                          getSelectedOption
                        } />
                    ))
                  }
                </div>
              </> : <DataNotFound message="No question found" className="h-screen w-full" />
            }
          </>

        }
      </>
    );
  }

export default QuestionsContainer;