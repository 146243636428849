import React, { FC } from 'react';
import { Outlet } from 'react-router';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import UserProvider from '../Providers/UserProvider';

const PrivateLayout: FC = () => {
  return (
    <UserProvider>
      <div className='h-screen bg-gradient-to-bl from-primary-500 to-primary-900 overflow-hidden'>
        <Header />
        <main className='p-2 md:p-3 text-primary-50 h-[calc(100%-60px)] overflow-auto'>
          <Outlet />
        </main>
        {/* <Footer /> */}
      </div>
    </UserProvider>
  );
};

export default PrivateLayout;
